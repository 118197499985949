// src/api.ts
import { ApiResponse } from '../types/types';

const API_BASE_URL = 'https://ologybrewing.com/wp-json/ology-custom/v1';

export const fetchMenuData = async (locationName: string): Promise<ApiResponse> => {
  try {
    const timestamp = new Date().getTime();
    const url = `${API_BASE_URL}/menus/${locationName}?t=${timestamp}`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const jsonData = await response.json();

    // Cache the data in localStorage
    localStorage.setItem("menuApiData", JSON.stringify(jsonData));

    return jsonData;
  } catch (error) {
    console.error('Error fetching menu data:', error);

    // Attempt to use locally stored data on error
    const locallyStoredData = localStorage.getItem("menuApiData");
    if (locallyStoredData) {
      return JSON.parse(locallyStoredData);
    }

    throw error;
  }
};
