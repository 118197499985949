import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MenuGrid from "./MenuGrid";
import { fetchMenuData } from "../utils/api";
import { ApiResponse } from "../types/types";
import FooterLegend from "./FooterLegend";
import styles from "./MenuPage.module.css";
import { sortBeers } from "../utils/sortBeers";
import { formatName } from "../utils/formatName";

const MenuPage: React.FC = () => {
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const { locationName = "" } = useParams<{ locationName?: string }>();

  useEffect(() => {
    if (!locationName) {
      return;
    }

    const formattedName = formatName(locationName);
    document.title = `${formattedName} Menu - Ology Brewing`;

    const loadData = async () => {
      try {
        const data = await fetchMenuData(locationName);
        console.log("Fetched menu data:", data);
        data.beers = sortBeers(data.beers);
        setApiResponse(data);
      } catch (error) {
        console.error("Failed to fetch menu data:", error);
      } finally {
        console.log("Menu data refreshed at ", new Date().toLocaleTimeString());
      }
    };

    // Load data initially and set up an interval to refresh it every 15 seconds
    loadData();
    const intervalId = setInterval(loadData, 15000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [locationName]);

  console.log("Menu data:", apiResponse);

  return (
    <div className={styles.menuPage}>
      {apiResponse && (
        <MenuGrid data={apiResponse} locationName={locationName} />
      )}
      <FooterLegend />
    </div>
  );
};

export default MenuPage;
