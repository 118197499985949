// src/utils/sortUtils.ts
import { MenuItem } from '../types/types';

const coreBeerOrder = ["Sensory Overload", "Rainbow Colored Glasses", "Italian Pilz", "Spa Goggles", "Blended Auras"];

export const sortBeers = (beers: MenuItem[]): MenuItem[] => {
    return beers.sort((a, b) => {
        const aIsCore = a.categories?.includes('Core');
        const bIsCore = b.categories?.includes('Core');

        if (aIsCore && bIsCore) {
            // If both beers are core, sort based on predefined order
            return coreBeerOrder.indexOf(a.title) - coreBeerOrder.indexOf(b.title);
        } else if (aIsCore) {
            return -1; // a is a core beer, so it comes first
        } else if (bIsCore) {
            return 1; // b is a core beer, so it comes after a
        } else {
            // If neither is a core beer, sort by ID
            return a.ID - b.ID;
        }
    });
};
