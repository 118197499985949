import React from "react";
import { ApiResponse } from "../types/types";
import MenuItem from "./MenuItem";
import styles from "./MenuGrid.module.css";

interface MenuGridProps {
  data: ApiResponse;
  locationName: string;
}

const MenuGrid: React.FC<MenuGridProps> = ({ data, locationName }) => {
  const { menus, beers } = data;
  const menuImages = menus[0]?.images || [];

  const totalItems = 20;
  const filledItems = beers.length;
  const emptySlots = Math.max(totalItems - filledItems, 0);

  // Create a combined array of API images and placeholders
  const combinedImages = [...menuImages.slice(0, emptySlots)];
  const defaultPlaceholder = `${process.env.PUBLIC_URL}/images/empty_tap_line.png`;
  const placeholderAltText = "Empty tap line icon";

  while (combinedImages.length < emptySlots) {
    combinedImages.push({
      id: "placeholder",
      url: defaultPlaceholder,
      alt: placeholderAltText,
    });
  }

  return (
    <div className={styles.menuGrid}>
      {beers.slice(0, totalItems).map((beer) => (
        <MenuItem key={beer.ID} item={beer} />
      ))}
      {combinedImages.map((image, index) => {
        const isPlaceholder = image.id === "placeholder";
        const altText = isPlaceholder
          ? `Empty tap line icon. No beer is currently on tap number ${
              index + 1
            }`
          : image.alt || `Menu item ${index + 1}`;

        return (
          <div key={`image-${index}`} className={styles.imageWrapper}>
            <img
              src={image.url}
              alt={altText}
              className={
                isPlaceholder ? styles.placeholderImage : styles.apiImage
              }
              onError={(e) => (e.currentTarget.src = defaultPlaceholder)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MenuGrid;
