// src/utils/getStyleColor.ts

// Define a mapping from beer styles to color codes
export const beerStyleColors: Record<string, string> = {
    IPA: '#8BBA51',      // Green
    Lager: '#DBB042',    // Darker Yellow
    Sour: '#EB5757',     // Red
    Stout: '#BB6BD9',    // Purple
    Other: '#4169E1'     // Dark Purple
  };
  
  // Utility function to get the color for a beer style
  export const getStyleColor = (style: string): string => {
    // Normalize the style to a common format, e.g., uppercase
    const normalizedStyle = style.toUpperCase();
  
    // Check if the style is one of the known styles
    if (normalizedStyle.includes('IPA')) return beerStyleColors.IPA;
    if (normalizedStyle.includes('LAGER') || normalizedStyle.includes('PILSNER')) return beerStyleColors.Lager;
    if (normalizedStyle.includes('SOUR')) return beerStyleColors.Sour;
    if (normalizedStyle.includes('STOUT')) return beerStyleColors.Stout;
  
    // If the style does not match any known styles, return 'Other'
    return beerStyleColors.Other;
  };
  