// src/components/FooterLegend.tsx
import React from "react";
import styles from "./FooterLegend.module.css";
import { beerStyleColors } from "../utils/getStyleColor"; // Import beerStyleColors

const FooterLegend: React.FC = () => {
  return (
    <div className={styles.footerLegend}>
      <span className={`${styles.footerText} ${styles.footerLegendItem}`}>
        Beer Styles:
      </span>
      <div
        className={styles.footerLegendItem}
        style={{ backgroundColor: beerStyleColors.IPA }}
      >
        IPA
      </div>
      <div
        className={styles.footerLegendItem}
        style={{ backgroundColor: beerStyleColors.Lager }}
      >
        Lager
      </div>
      <div
        className={styles.footerLegendItem}
        style={{ backgroundColor: beerStyleColors.Sour }}
      >
        Sour
      </div>
      <div
        className={styles.footerLegendItem}
        style={{ backgroundColor: beerStyleColors.Stout }}
      >
        Stout
      </div>
      <div
        className={styles.footerLegendItem}
        style={{ backgroundColor: beerStyleColors.Other }}
      >
        Other
      </div>
    </div>
  );
};

export default FooterLegend;
