export function decodeHTML(text: string): string {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    let decodedText = textArea.value;
  
    // Replace \n and \r with <br> tags to render them as line breaks in HTML
    decodedText = decodedText.replace(/\n/g, '<br>').replace(/\r/g, '<br>');
  
    return decodedText;
  }
  