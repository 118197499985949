import React from "react";
import { MenuItem as MenuItemType, Container } from "../types/types"; // Import the correct types
import { getStyleColor } from "../utils/getStyleColor";
import styles from "./MenuItem.module.css";
import { decodeHTML } from "../utils/decodeHTML";

interface MenuItemProps {
  item: MenuItemType;
}

const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
  const styleColor = getStyleColor(item.style);

  return (
    <div className={styles.menuItem}>
      <div
        className={styles.styleIndicator}
        style={{ backgroundColor: styleColor }}
      ></div>
      <div className={styles.content}>
        <div className={styles.itemDetails}>
          <h3 className={styles.itemName}>{item.title}</h3>
          <p
            className={styles.itemDescription}
            dangerouslySetInnerHTML={{ __html: decodeHTML(item.description) }}
          ></p>
        </div>
        <div className={styles.containerList}>
          {item.containers.map((container: Container, index: number) => (
            <div key={index} className={styles.container}>
              <span className={styles.containerSize}>{container.name}</span>
              <span className={styles.containerPrice}>${container.price}</span>
            </div>
          ))}
        </div>
      </div>
      <p className={styles.itemAbv}>{item.abv}% ABV</p>
    </div>
  );
};

export default MenuItem;
